//ALERTAS
export const alertsModules = {
    feature_code: 'FEAT-351',
    enabled: false,
    children: [
        {
            feature_code: 'FEAT-352',
            enabled: false,
        },
        {
            feature_code: 'FEAT-353',
            enabled: false,
        },
        {
            feature_code: 'FEAT-488',
            enabled: false,
        },
        {
            feature_code: 'FEAT-489',
            enabled: false,
        },
    ]
}