const PhoneIcon = ({ color = '#FFFFFF', width = '19px' }) => {
    return (
        <svg width={width} height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="telefono-movil">
                <g mask="url(#mask0_3383_11676)">
                    <g id="Group">
                        <path id="Shape" fillRule="evenodd" clipRule="evenodd" d="M14.2082 0H5.79332C4.98799 0 4.33789 0.653014 4.33789 1.45689V18.5446C4.33789 19.3477 4.98799 20 5.79332 20H14.2082C15.012 20 15.6636 19.3477 15.6636 18.5446V1.45689C15.6636 0.653014 15.012 0 14.2082 0ZM7.92435 0.884766H12.0786C12.1835 0.884766 12.2688 1.04146 12.2688 1.23532C12.2688 1.42919 12.1835 1.58661 12.0786 1.58661H7.92435C7.81868 1.58661 7.73486 1.42919 7.73486 1.23532C7.73486 1.04146 7.81868 0.884766 7.92435 0.884766ZM10.0015 18.5621C9.48914 18.5621 9.07227 18.1452 9.07227 17.6321C9.07227 17.119 9.48915 16.7036 10.0015 16.7036C10.5124 16.7036 10.9293 17.119 10.9293 17.6321C10.9293 18.1452 10.5124 18.5621 10.0015 18.5621ZM14.5733 15.3779H5.42896V2.45825H14.5733V15.3779H14.5733Z" fill={color} />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default PhoneIcon