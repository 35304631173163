import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  statusRoad: null,
  ticketById: null,
  flags: null,
  ticketCategory: [],
  employeeTicket: [],
  refubishedTicket: {
    flags: {},
    ticket: {},
    status_road: [],
  },
}

export const detailTicketByIdSlice = createSlice({
  name: "detailTicketById",
  initialState,
  reducers: {
    setTicketRefubishedById: (state, { payload }) => {
      const { data } = payload;
      state.refubishedTicket = data;
    },
    setTicketById: (state, { payload }) => {
      const { data } = payload;
      state.statusRoad = data?.statusRoad;
      state.ticketById = data?.ticket;
      state.flags = data?.flags;
    },
    setTicketCategory: (state, { payload }) => {
      const { data } = payload;
      state.ticketCategory = data;
    },
    setEmployee: (state, { payload }) => {
      const { data } = payload;
      state.employeeTicket = data?.dataSource;
    },
    cleanTicketById: (state) => {
      state.statusRoad = null;
      state.ticketById = null;
      state.flags = null;
      state.ticketCategory = [];
      state.employeeTicket = [];
      state.refubishedTicket = {
        flags: {},
        ticket: {},
        status_road: [],
      };
    },

  },
});

export const { setTicketById, setTicketCategory, setEmployee, setTicketRefubishedById, cleanTicketById } =
  detailTicketByIdSlice.actions;
