//CHECKLIST DE ORNATO E IMAGEN ✅

export const ornamentImageChecklistModule = {
    feature_code: 'FEAT-451',
    enabled: false,
    children: [
        {
            feature_code: 'FEAT-452',
            enabled: false,
        },
        {
            feature_code: 'FEAT-453',
            enabled: false,
        },
        {
            feature_code: 'FEAT-456',
            enabled: false,
        },
        {
            feature_code: 'FEAT-454',
            enabled: false,
        },
        {
            feature_code: 'FEAT-455',
            enabled: false,
        },
        {
            feature_code: 'FEAT-457',
            enabled: false,
            children: [
                {
                    feature_code: 'FEAT-458',
                    enabled: false,
                },
                {
                    feature_code: 'FEAT-459',
                    enabled: false,
                },
                {
                    feature_code: 'FEAT-460',
                    enabled: false,
                },
                {
                    feature_code: 'FEAT-461',
                    enabled: false,
                },
                {
                    feature_code: 'FEAT-462',
                    enabled: false,
                    children: [
                        {
                            feature_code: 'FEAT-463',
                            enabled: false,
                        },
                        {
                            feature_code: 'FEAT-464',
                            enabled: false,
                        },
                        {
                            feature_code: 'FEAT-465',
                            enabled: false,
                        },
                    ]
                },
            ]
        },
    ]
}