import { useState } from "react"
import useAlerts from "../../hooks/useAlerts"
import { Col, Divider, Image, Row } from "antd"
import CustomDrawer from "@UI/CustomDrawer"
import { CustomInput } from "@UI/CustomInput"
import AlertCoordsModal from "./modals/AlertCoordsModal"
import { CustomSpin } from '@UI/CustomSpin'
import AlertActionsFooter from "./layout/AlertActionsFooter"
import { setAlertSelectedDetail } from "@store/alerts"
import useOpenCloseModal from '@thooks/useOpenCloseModal'
import useCurrentLocation from '@thooks/useCurrentLocation'
import { useAppDispatch, useAppSelector } from "@hooks/store"

// Archivos png o svg
import { CloseOutlined } from "@ant-design/icons"
import PhoneIcon from "@assets/icons/PhoneIcon"
import { ReactComponent as DetailCardUser } from '@assets/icons/assign.svg'
import userImage from '@assets/icons_png/avatar_user.png'
import detailAlertIcon from '@assets/icons/Generals/alert-detail-icon.svg'

import './styles.css'

const DetailAlertDrawer = ({
  isOpenDrawer,
  handleOpenCloseDrawer,
}) => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('')

  const onChangeValue = (e) => setValue(e.target.value)

  const { location } = useCurrentLocation()
  const { getAllAlerts, updateAlertData } = useAlerts()
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
  const { alertSelectedDetail } = useAppSelector(state => state.alerts)  

  // Se valida si realmente en la drawer estoy en la pagina de alertas o no porque esto evita el isLimit en
  // la pagina de alertas
  const isInAlertsPage = location.pathname === '/'

  // Se extraen las primeras dos posiciones para validar si es am o pm la fecha de creacion
  const isAmOrPm = parseInt(alertSelectedDetail?.created_time?.slice(0, 2) ?? '0')
  // Se cambia el formato YYYY-MM-DD a YYYY/MM/DD
  const dateFormated = alertSelectedDetail?.created_at?.replaceAll('-', '/')

  const formattedPhone = alertSelectedDetail?.user_from_data?.phone
    ? (alertSelectedDetail?.user_from_data?.phone)?.slice(0, 3) + '-' + (alertSelectedDetail?.user_from_data?.phone)?.slice(3)
    : 'No se encuentra un teléfono'

  // Funcion encargada de cerrar la drawer y limpiar la store en la redux para la alerta antes seleccionada
  const handleOnCloseDrawer = () => {
    dispatch(setAlertSelectedDetail({}))
    handleOpenCloseDrawer()
  }

  // Se valida declara primero variable para sobre escribir su contenido si y solo si existen imagenes adjuntas
  // a la informacion detallada de una alerta
  let body = (
    <Col span={24}>
      <span className='label-drawer'>
        MENSAJE:
      </span>

      <p>
        {alertSelectedDetail.message}
      </p>
    </Col>
  )

  // Si existiese alguna imagen o ambas se muestra con otra estructura HTML
  if (alertSelectedDetail.imageOne || alertSelectedDetail.imageTwo) {
    body = (
      <>
        <Col span={14}>
          <span className='label-drawer'>
            MENSAJE:
          </span>

          <p>
            {alertSelectedDetail.message}
          </p>
        </Col>

        <Col span={10}>
          <Row justify="center" gutter={16}>
            {alertSelectedDetail.imageOne && (
              <Col>
                <Image
                  width={100}
                  height={80}
                  alt="img-one"
                  src={alertSelectedDetail.imageOne}
                  style={{ borderRadius: 8, objectFit: 'cover' }}
                />
              </Col>
            )}

            {alertSelectedDetail.imageTwo && (
              <Col>
                <Image
                  width={100}
                  height={80}
                  alt="img-two"
                  src={alertSelectedDetail.imageTwo}
                  style={{ borderRadius: 8, objectFit: 'cover' }}
                />
              </Col>
            )}
          </Row>
        </Col>
      </>
    )
  }

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      // Esperamos la respuesta actualizada de firebase para las alertas activas
      const alertsActive = await getAllAlerts({ isLimit: isInAlertsPage })

      // Filtramos la alerta activa actualizada
      const updatedAlert = alertsActive?.filter(alert => alert.id === alertSelectedDetail.id)

      dispatch(setAlertSelectedDetail(updatedAlert[0] ?? {}))
    }
  }

  const onSubmit = async (actionId) => {
    setLoading(true)

    let objAlertsData = {
      '1': { archivar: true },
      '2': { answer: value },
      'default': {}
    }

    const dataToSend = objAlertsData[actionId] || objAlertsData['default']

    const response = await updateAlertData(alertSelectedDetail.id, dataToSend)

    await onFinish(response)
    setLoading(false)
  }

  return (
    <>
      <CustomDrawer
        width={650}
        title="DATOS DE ALERTA"
        className="alerts-drawer"
        isOpenDrawer={isOpenDrawer}
        onClose={handleOnCloseDrawer}
        footer={<AlertActionsFooter value={value} setValue={setValue} loading={loading} onSubmit={onSubmit} handleOpenCloseCoordsModal={handleOpenCloseModal} />}
        closeIcon={<CloseOutlined style={{ color: "var(--primary)", fontSize: 20 }} />}
      >
        {loading
          ? <CustomSpin spinning={loading} size='middle' tip="Actualizando" />
          : <>
            <Row align="middle">
              <Col span={14}>
                <Row align="middle" gutter={16}>
                  <Col>
                    <img src={detailAlertIcon} alt="alert-icon" />
                  </Col>

                  <Col>
                    <span className="label-drawer">ALERTA</span>

                    <h3 style={{ margin: 0, fontWeight: 500, fontSize: 20, color: '#232C4A' }}>
                      {alertSelectedDetail?.asunto}
                    </h3>
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <span className="label-drawer">
                  RECIBIDA
                </span>
                <p style={{ fontWeight: 500, fontSize: 14, color: '#404040', margin: 0 }}>
                  {alertSelectedDetail?.created_time} {isAmOrPm < 12 ? 'am' : 'pm'}
                  {' '}
                  -
                  {' '}
                  {dateFormated}
                </p>
              </Col>
            </Row>

            <Divider type="horizontal" style={{ borderTop: "1px solid #C9C9C9" }} />

            <Row align="middle">
              <Col span={24}>
                <span className="label-drawer">
                  USUARIO QUE REPORTA:
                </span>

                <Row align="middle" style={{ marginTop: 10 }}>
                  <Col span={3}>
                    <Image
                      alt="user-png"
                      src={alertSelectedDetail?.user_from_data?.image ? alertSelectedDetail?.user_from_data?.image : userImage}
                      style={{ width: 56, height: 56, border: "1px solid #C9D7D8", borderRadius: '50%', objectFit: 'cover' }}
                    />
                  </Col>

                  <Col span={13}>
                    <Row align="middle">
                      <span style={{ fontWeight: 500, fontSize: 16, color: '#232C4A', marginRight: 8 }}>
                        {(alertSelectedDetail?.user_from_data?.name && alertSelectedDetail?.user_from_data?.lastname)
                          ? `${alertSelectedDetail?.user_from_data?.name} ${alertSelectedDetail?.user_from_data?.lastname}`
                          : alertSelectedDetail.nameUser
                        }
                      </span>

                      <DetailCardUser />
                    </Row>

                    <Row>
                      <span style={{ fontWeight: 400, fontSize: 14, color: '#404040' }}>
                        {alertSelectedDetail?.address ? alertSelectedDetail.address : 'No se encuentra una dirección'}
                      </span>
                    </Row>
                  </Col>

                  <Col span={8}>
                    <Row>
                      <PhoneIcon color="var(--primary)" />

                      <span style={{ fontWeight: 400, fontSize: 16, color: "var(--primary)", marginLeft: 5 }}>
                        {formattedPhone}
                      </span>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Divider type="horizontal" style={{ borderTop: "1px solid #C9C9C9" }} />

            <Row align="middle" gutter={16}>
              {body}
            </Row>

            <Divider type="horizontal" style={{ borderTop: "1px solid #C9C9C9" }} />

            <Row>
              <Col span={24}>
                <span className='label-drawer'>
                  RESPUESTA ALERTA:
                </span>

                {alertSelectedDetail.answer
                  ? <p> {alertSelectedDetail.answer} </p>
                  : <p>No hay una respuesta por el momento</p>
                }

                <Divider type="horizontal" style={{ borderTop: "1px solid #C9C9C9" }} />

                <Row style={{ border: '1px solid ##D8D8D8', borderRadius: 8 }}>
                  {alertSelectedDetail.answer
                    ? <p>Puedes escribir una respuesta de nuevo:</p>
                    : <p>Escribe una respuesta</p>
                  }

                  <Col span={24}>
                    <CustomInput value={value} placeholder="Escribe una respuesta" onChange={onChangeValue} type="AREA" autoSize={{ minRows: 3, maxRows: 8 }} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        }
      </CustomDrawer>

      <AlertCoordsModal isOpenModal={isOpenModal} handleOpenCloseModal={handleOpenCloseModal} />
    </>
  )
}

export default DetailAlertDrawer