import { Button, Col, Row } from "antd"
import { useAppSelector } from "@hooks/store"

// Imagenes o archivos svg
import { ReactComponent as SaveIcon } from '@assets/icons/Generals/tag-icon.svg'
import CustomPopoverConfirm from "@UI/CustomPopoverConfirm"
import { ALERTS_CODES } from "@constants/modules/alerts/alertsCodes"

const AlertActionsFooter = ({
  value,
  loading,
  onSubmit,
  setValue,
  handleOpenCloseCoordsModal
}) => {
  const { alertSelectedDetail } = useAppSelector(state => state.alerts)
  const { activeFeatures } = useAppSelector(state => state.roles)

  const isCoords = alertSelectedDetail?.coords ? alertSelectedDetail.coords : null

  return (
    <Row>
      <Col span={24}>
        <Row align="middle" justify="space-between">
          <Col>
            <CustomPopoverConfirm
              maxWidthContainer={152}
              trigger={'hover'}
              content={`${!activeFeatures?.find(feature => feature.feature_code === ALERTS_CODES.ALERTS_ARCHIVE_ALERT)?.enabled ? 'No tienes permiso para realizar esta acción' : 'ARCHIVAR'}`}
            >
              <Button
                type="text"
                htmlType="button"
                shape="round"
                loading={loading}
                disabled={alertSelectedDetail?.archivar || !activeFeatures?.find(feature => feature.feature_code === ALERTS_CODES.ALERTS_ARCHIVE_ALERT)?.enabled}
                onClick={() => {
                  onSubmit('1')
                }}
                style={{ width: 174, border: 0, opacity: ((alertSelectedDetail?.archivar) || (loading)) ? 0.5 : 1 }}
              >
                <Row justify="space-evenly" align="middle">
                  <SaveIcon />
                  <span style={{ fontSize: 14, fontWeight: 500, color: 'var(--primary)' }}>ARCHIVAR</span>
                </Row>
              </Button>
            </CustomPopoverConfirm>
          </Col>

          <Col>
            <Row justify="end" gutter={16}>
              <Col>
                <Button
                  type="text"
                  htmlType="button"
                  shape="round"
                  loading={loading}
                  disabled={!isCoords}
                  style={{ width: 216, opacity: isCoords ? 1 : 0.5 }}
                  onClick={handleOpenCloseCoordsModal}
                  className="btn-standard-white"
                >
                  <span>VER COORDENADAS</span>
                </Button>
              </Col>

              <Col>
                <CustomPopoverConfirm
                  maxWidthContainer={152}
                  trigger={'hover'}
                  placement={'left'}
                  content={`${!activeFeatures?.find(feature => feature.feature_code === ALERTS_CODES.ALERTS_ANSWER_ALERT)?.enabled ? 'No tienes permiso para realizar esta acción' : 'ENVIAR'}`}
                >
                  <Button
                    type="text"
                    shape="round"
                    loading={loading}
                    htmlType="button"
                    disabled={!(value.length) || !activeFeatures?.find(feature => feature.feature_code === ALERTS_CODES.ALERTS_ANSWER_ALERT)?.enabled}
                    onClick={() => {
                      onSubmit('2')
                      setValue('')
                    }}
                    style={{ width: 130, opacity: (!(value.length)) ? 0.5 : 1 }}
                    className="btn-standard-green"
                  >
                    <span>ENVIAR</span>
                  </Button>
                </CustomPopoverConfirm>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default AlertActionsFooter
