//CHECKLIST DE ORNATO E IMAGEN ✅

export const userRolesModule = {
    feature_code: 'FEAT-442',
    enabled: false,
    children: [
        {
            feature_code: 'FEAT-443',
            enabled: false,
        },
        {
            feature_code: 'FEAT-444',
            enabled: false,
        },
        {
            feature_code: 'FEAT-445',
            enabled: false,
        },
        {
            feature_code: 'FEAT-446',
            enabled: false,
        },
        {
            feature_code: 'FEAT-447',
            enabled: false,
        },
        {
            feature_code: 'FEAT-448',
            enabled: false,
            children: [
                {
                    feature_code: 'FEAT-449',
                    enabled: false,
                },
                {
                    feature_code: 'FEAT-450',
                    enabled: false,
                },                
            ]
        },
    ]
}