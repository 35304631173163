//TICKETS ⚠️

const CATEGORY_TICKETS = [
    {
        feature_code: 'FEAT-436',
        enabled: false,
        children: [
            {
                feature_code: 'FEAT-437',
                enabled: false,
            },
            {
                feature_code: 'FEAT-438',
                enabled: false,
            },
            {
                feature_code: 'FEAT-439',
                enabled: false,
            },
            {
                feature_code: 'FEAT-440',
                enabled: false,
            },
        ]
    }
]

const COMMON_FEATURES = [
    {
        feature_code: 'FEAT-277',
        enabled: false,
    },
    {
        feature_code: 'FEAT-486',
        enabled: false,
    },
    {
        feature_code: 'FEAT-22',
        enabled: false,
    },
    {
        feature_code: 'FEAT-18',
        enabled: false,
        children: [
            {
                feature_code: 'FEAT-337',
                enabled: false,
            },
            {
                feature_code: 'FEAT-19',
                enabled: false,
            },
            {
                feature_code: 'FEAT-20',
                enabled: false,
            },
            {
                feature_code: 'FEAT-22',
                enabled: false,
            },
            {
                feature_code: 'FEAT-336',
                enabled: false,
            },
            {
                feature_code: 'FEAT-157',
                enabled: false,
            },
            {
                feature_code: 'FEAT-338',
                enabled: false,
            },
            {
                feature_code: 'FEAT-24',
                enabled: false,
            },
            {
                feature_code: 'FEAT-26',
                enabled: false,
            },
            {
                feature_code: 'FEAT-28',
                enabled: false,
            },
            {
                feature_code: 'FEAT-27',
                enabled: false,
            },
            {
                feature_code: 'FEAT-481',
                enabled: false,
            },
        ]
    },
    ...CATEGORY_TICKETS,
]

export const ticketsModule = [
    {
        feature_code: 'FEAT-253',
        enabled: false,
        children: [
            {
                feature_code: 'FEAT-254',
                enabled: false,
                children: [
                    ...COMMON_FEATURES,
                    {
                        feature_code: 'FEAT-260',
                        enabled: false,
                    },
                ],
            },
            {
                feature_code: 'FEAT-8',
                enabled: false,
                children: COMMON_FEATURES,
            },
            {
                feature_code: 'FEAT-485',
                enabled: false,
                children: COMMON_FEATURES,
            },
        ]
    },
    {
        feature_code: 'FEAT-485',
        enabled: false,
    },
    {
        feature_code: 'FEAT-23',
        enabled: false,
    },
    {
        feature_code: 'FEAT-304',
        enabled: false,
        children: [
            {
                feature_code: 'FEAT-18',
                enabled: false,
            }
        ]
    },
]

