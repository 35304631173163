import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  adminChecklists: {
    columns: [],
    dataSource: []
  },
  filters: {
    checklistId: null,
    employeeId: null,
    dateBegin: null,
    dateEnd: null,
    checklistIdStatus: null,
    skip: 0,
    limit: 10,
    sortOrder: null,
    sortBy: null
  },
  metadata: { limit: 10, quantity: 0 },
  checklistClicked: {},
  ornamentStatusChecklist: [],
  activeOrnamentChecklist: [],
  loadingOrnamentChecklists: false,
}

export const adminChecklistSlice = createSlice({
  name: 'adminCheckSlice',
  initialState,
  reducers: {
    setAdminChecklists: (state, { payload }) => {
      state.adminChecklists = payload
    },
    setFilters: (state, { payload }) => {
      state.filters = payload
    },
    setMetadata: (state, { payload }) => {
      state.metadata = payload
    },
    setLoadingOrnamentChecklists: (state, { payload }) => {
      state.loadingOrnamentChecklists = payload
    },
    setChecklistClicked: (state, { payload }) => {
      state.checklistClicked = payload
    },
    setOrnamentStatusChecklist: (state, { payload }) => {
      state.ornamentStatusChecklist = payload
    },
    setActiveOrnamentChecklist: (state, { payload }) => {
      state.activeOrnamentChecklist = payload
    },
  }
})

export const {
  setFilters,
  setMetadata,
  setAdminChecklists,
  setChecklistClicked,
  setActiveOrnamentChecklist,
  setOrnamentStatusChecklist,
  setLoadingOrnamentChecklists,
} = adminChecklistSlice.actions