import React from "react";
import ReactDOM from "react-dom/client";
import { AppRouter } from "./routes/AppRouter";
import { Provider } from "react-redux";
import { store } from "./store";
import { ToastContainer } from "react-toastify";
import { CountDownProvider } from "./context/CountDownContext";
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import "./index.css";
import './antdStyle.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ToastContainer />
    <CountDownProvider>
      <AppRouter />
    </CountDownProvider>
  </Provider>
);

// Registra el Service Worker solo en producción
if (process.env.NODE_ENV === 'production') {
  serviceWorkerRegistration.register({
    onUpdate: registration => {
      const updateAvailable = window.confirm("Hay una nueva versión disponible. ¿Deseas actualizar?");
      if (updateAvailable) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
        window.location.reload();
      }
    }
  });
}