import { Routes, Route, Navigate } from "react-router-dom"
import { Sidebar } from "../components/Sidebar/Sidebar"
import { NavBar } from "../components/NavBar";
import { Layout } from "antd"
import { lazy, Suspense } from "react";
import { useAppSelector } from "../../hooks/store"
import { CustomSpin } from "../UI/CustomSpin";
// import { PaymentsPage } from "../pages/payments/PaymentsPage";
// import { ReservationsRequestPage } from "../pages/reservationsRequest/ReservationsRequestPage";
// import { AnnouncementPage } from "../pages/announcement/AnnouncementPage";
// import { AdminCobrosPage } from "../pages/adminCobros/AdminCobrosPage";
// import { ZonasReceivedPage } from "../pages/zonas/ZonasReceivedPage";
// import UserProfileActivityPage from "../pages/NewUsers/components/activityUser/UserProfileActivityPage";
import { AlertsReceivedPage } from "../pages/alertsReceived/AlertsReceivedPage"
const SurveyPage = lazy(() => import("../pages/Surveys/SurveyPage"))
const TicketPage = lazy(() => import("../pages/Tickets/TicketPage"))
const ConfiguracionInicialPage = lazy(() => import("./../pages/configuracionInicial/ConfiguracionInicialPage"))
const NomenclaturaPage = lazy(() => import("../pages/configuracionInicial/nomenclatura/NomenclaturaPage"))
const BuildingSketchPage = lazy(() => import("../pages/buildingSketchs/BuildingSketchPage"))
const EmployeedManagementPage = lazy(() => import("../pages/employeedManagement/EmployeedManagementPage"))
const DefaultPage = lazy(() => import("../mocks/DefaultPages"))
const ActivesMaintenancePage = lazy(() => import("../pages/activesMaintenance/ActivesMaintenancePage"))
const SurveyContextProvider = lazy(() => import("../pages/Surveys/contexts/SurveyContext"))
const SurveyFormContextProvidrer = lazy(() => import("../pages/Surveys/contexts/SurveyFormContext"))
const HierarchyAdministrationPage = lazy(() => import("../pages/hierarchyAdministration/HierarchyAdministrationPage"))
const LibraryPage = lazy(() => import('../pages/library/LibraryPage'))
const BookingPage = lazy(() => import("../pages/bookings/BookingPage"))
const HomePage = lazy(() => import("../pages/home/HomePage"))
const AccessProvidersPage = lazy(() => import("../pages/accessProviders/AccessProvidersPage"))
const AmenitiesPage = lazy(() => import("../pages/adminAmenities/AmenitiesPage"))
const SalesStatementPage = lazy(() => import("../pages/salesStatement/SalesStatementPage"))
const MenuPage = lazy(() => import("../components/Menu/MenuPage"))
const AccessPage = lazy(() => import("../pages/access/AccessPage"))
const TypesNewsPage = lazy(() => import("../pages/typesNews/TypesNewsPage"))
const UserRolesMainPage = lazy(() => import("../pages/userRoles/UserRolesMainPage"))
const ConversationsPage = lazy(() => import("../pages/messages/ConversationsPage"))
const UsersPage = lazy(() => import("../pages/users/UsersPage"))
const AdminContractsPage = lazy(() => import("../pages/adminContracts/AdminContractsPage"))
const ChecklistPage = lazy(() => import("@checklist/ChecklistPage"))
const CheckListCategoryPage = lazy(() => import("@checklist/pages/checklistCategory/CheckListCategoryPage"))
const AdminCheckPage = lazy(() => import("@checklist/pages/adminCheck/AdminCheckPage"))
const ActivityMonitoringPage = lazy(() => import("@checklist/pages/activityMonitoring/ActivityMonitoringPage"))
const AdminChecklistItemsTasksPage = lazy(() => import("@checklist/pages/adminChecklists/components/checklistAdmin/components/adminChecklistTasks/AdminChecklistItemsTasksPage"))
const ImageChecklistPage = lazy(() => import("@checklist/pages/adminChecklists/ImageChecklistPage"))
const TypeAdminCheckPage = lazy(() => import("@checklist/pages/adminChecklists/components/TypeAdminCheckPage"))
const ChecklistsAdminPage = lazy(() => import("@checklist/pages/checklistsAdmin/ChecklistsAdminPage"))
const HandoverChecklist = lazy(() => import("@checklist/pages/handoverCheck/HandoverChecklistPage"))
const ReceptionCheck = lazy(() => import("@checklist/pages/receptionCheck/ReceptionCheck"))

const { Header, Sider, Content } = Layout;

export const DashboardRouter = () => {
  const theme = useAppSelector((state) => state.sidebarTheme);

  return (
    <Layout className="wrapper-container">
      <Sider
        className="sidebar"
        trigger={null}
        collapsible
        collapsed={true}
        //Cambiar el color del sider segun el theme desde la API
        style={{ height: "100vh", background: theme?.sidebarBackground }}
      >
        <Sidebar />
      </Sider>

      <Layout className="site-layout">
        <Header style={{ padding: 0 }}>
          <NavBar />
        </Header>

        <Content style={{ minHeight: 280 }}>
          <Suspense fallback={<CustomSpin tip="Cargando módulo" />}>
            <Routes>
              {/* GENERAL ROUTES */}
              <Route index element={<DefaultPage />} />
              <Route path="homePage" element={<HomePage />} />

              {/**MENU ROUTES */}
              <Route path="/menu/:menuId" element={<MenuPage />} />
              <Route path="/menu/:menuId/:submenuId" element={<MenuPage />} />

              <Route path="access/residential-access" element={<AccessPage />} />
              <Route path="access/providers-access" element={<AccessProvidersPage />} />

              {/**SURVEYS ROUTE */}
              <Route path="surveys" element={
                <SurveyContextProvider>
                  <SurveyFormContextProvidrer>
                    <SurveyPage />
                  </SurveyFormContextProvidrer>
                </SurveyContextProvider>
              } />
              {/* <Route path="destacados" element={<ImportantPage />} /> */}
              {/* <Route path="payments" element={<PaymentsPage />} /> */}
              {/*
            <Route
              path="zones"
              element={
                <ZonasReceivedPage
                  residentialData={authData.committee.residentialData}
                />
              }
            />
            <Route
              path="solicitudes-de-reserva"
              element={<ReservationsRequestPage />}
            />
            <Route path="announcements" element={<AnnouncementPage />} />
            <Route path="collection-managment" element={<AdminCobrosPage />} /> */}

              {/* ALERTAS */}
              <Route path="alertas" element={<AlertsReceivedPage />} />

              {/* ADMINISTRATION ROUTES */}
              <Route path="settings/managment-spaces" element={<HierarchyAdministrationPage />} />
              <Route path="settings/import-users" element={<NomenclaturaPage />} />
              <Route path="settings/users-profiles" element={<UsersPage />} />
              <Route path="settings/managment-employees" element={<EmployeedManagementPage />} />
              <Route path="settings/initial-settings" element={<ConfiguracionInicialPage />} />

              {/* ACTIVE MAINTENANCE ROUTE */}
              <Route path="settings/active-maintenance/" element={<ActivesMaintenancePage />} />
              <Route path="settings/active-maintenance/:activeId" element={<ActivesMaintenancePage />} />
              <Route path="settings/active-maintenance/:activeId/:historyId" element={<ActivesMaintenancePage />} />
              <Route path="settings/active-maintenance/:activeId/:historyId/:historyAction" element={<ActivesMaintenancePage />} />
              <Route path="settings/active-maintenance/:activeId/:historyId/:historyAction/:reportId" element={<ActivesMaintenancePage />} />

              {/* BOOKING ROUTES */}
              <Route path="bookings" element={<BookingPage />} />

              {/*AMENITITES ROUTES */}
              <Route path="/settings/managment-amenities" element={<AmenitiesPage />} />
              <Route path="/settings/managment-amenities/:idAmenity" element={<AmenitiesPage />} />

              {/* NEWS ROUTES */}
              <Route path="news" element={<TypesNewsPage />} />
              <Route path="news/:idNew" element={<TypesNewsPage />} />

              {/* TICKETS ROUTES */}
              <Route path="tickets/:typeTickets" element={<TicketPage />} />
              <Route path="tickets/:typeTickets/:pageTicket" element={<TicketPage />} />
              <Route path="tickets/:typeTickets/:pageTicket/:idTicket" element={<TicketPage />} />

              {/* MALLS ROUTES */}
              <Route path="mapas" element={<BuildingSketchPage />} />
              <Route path="contracts" element={<AdminContractsPage />} />

              {/* NEW TYPES CHECKLISTS ADMIN */}
              <Route path="/checklists-admin" element={<ChecklistsAdminPage />} />

              {/* ADMIN CHECKLISTS */}
              <Route path="/admin-check" element={<ImageChecklistPage />} />
              <Route path="/admin-check/:checklistIdPage" element={<TypeAdminCheckPage />} />
              <Route path="/admin-check/:checklistIdPage/:checklistCategoryId" element={<AdminChecklistItemsTasksPage />} />

              {/* CHECKLIST ROUTES */}
              <Route path="checklist-categorias/:checklistId" element={<CheckListCategoryPage />} />

              <Route path="checklist/:checkId" element={<ChecklistPage />} />
              <Route path="/checklist/admin-entrega/:checklistId" element={<ReceptionCheck />} />

              <Route path="checklist/admin/:adminCheckId/" element={<AdminCheckPage />} />
              <Route path="checklist/admin/:adminCheckId/:idTaskChecklist" element={<AdminCheckPage />} />

              <Route path="checklist/monitoring/:action/" element={<ActivityMonitoringPage />} />
              <Route path="checklist/monitoring/:action/:idDetail" element={<ActivityMonitoringPage />} />

              <Route path="checklist-entrega/:checklistId" element={<HandoverChecklist />} />
              <Route path="checklist-entrega/:checklistId/:checklistTaskId" element={<HandoverChecklist />} />

              {/* LIBRARY ROUTE */}
              <Route path="library" element={<LibraryPage />} />

              {/* DECLARACION DE VENTAS */}
              <Route path="settings/sales-statement" element={<SalesStatementPage />} />

              {/**ADMON. DE ROLES */}
              <Route path="/settings/managment-roles" element={<UserRolesMainPage />} />
              <Route path="/settings/managment-roles/:roleId" element={<UserRolesMainPage />} />
              <Route path="/settings/managment-roles/:roleId/:subId" element={<UserRolesMainPage />} />

              {/* MENSAJERIA */}
              <Route path="/messages" element={<ConversationsPage />} />

              <Route
                path="/*"
                element={<Navigate to="/404" />}
              />

              {/**OTHER ROUTES */}
              {/* <Route path="user-profile-activity-page" element={<UserProfileActivityPage />} /> */}
              <Route path="/*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};