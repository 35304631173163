// src/serviceWorkerRegistration.js
export function register(config) {
  if ('serviceWorker' in navigator) {
    const swUrl = `${process.env.REACT_APP_URL}/service-worker.js`;

    navigator.serviceWorker.register(swUrl).then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker) {
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // Llama a la función `onUpdate` cuando haya una nueva versión
                if (config && config.onUpdate) {
                  console.info('¡Aplicando nueva versión de la aplicación!');
                  config.onUpdate(registration);
                }
              }
            }
          };
        }
      };
    }).catch(error => {
      console.error('Error durante el registro del Service Worker:', error);
    });
  }
}
